<template>
  <div class="box">
    <div class="banner">
      <div class="box">
        <div class="left">
          <img src="/img/zhuceleft.png" alt="" />
        </div>
        <div class="right">
          <p class="title-font">
            重置密码
          </p>
          <el-form ref="form" style="padding: 29px 30px 30px 30px;">
            <el-form-item prop="phone">
              <el-input placeholder="请输入手机号" v-model="phone"></el-input>
            </el-form-item>
            <el-form-item prop="phonenum">
              <el-col :span="16">
                <el-input placeholder="请输入验证码" v-model="phonenum"></el-input>
              </el-col>
              <el-col :offset="1" :span="7">
                <el-button style="width: 100%; color: rgb(81, 85, 92)" :disabled="loginVcTime < defaultTimeOutNum" @click="onClickGetVc">{{loginVcTime < defaultTimeOutNum ? loginVcTime : '获取验证码'}}</el-button>
              </el-col>
            </el-form-item>
            <el-form-item prop="password">
              <el-input placeholder="请输入密码" v-model="password" show-password></el-input>
            </el-form-item>
            <el-form-item>
              <el-button @click="jump" class="submit-btn">提交</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { ajax } from "@/utils/ajax";

const defaultTimeOutNum = 60

export default {
  name: "fillInTheOrder",
  data() {
    return {
      form: {
        phone: "",
        phonenum: "",
      },
      phone: '',
      phonenum: '',
      password: '',
      loginVcTime: defaultTimeOutNum,
      defaultTimeOutNum,
      timeOut: null,
    };
  },
  methods: {
    onClickGetVc() {
      if (this.phone == '') {
        this.$message.error("请输入手机号");
        return false
      }
      const reg = /^1[34578]\d{9}$/;
      if (!reg.test(this.phone)) {
        this.$message.error("请输入正确的手机号");
        return false
      }
      const that = this
      ajax.get(
        '/TokenAuth/SeedResetNumber', {
        phone: that.phone
      }).then(res => {
        if (res.result.success) {
          that.timeOut = setInterval(() => {
            if (that.loginVcTime <= 0) {
              clearInterval(that.timeOut)
              that.loginVcTime = defaultTimeOutNum
            } else {
              --that.loginVcTime
            }
          }, 500)
        } else {
          this.$message.error(res.result.msg);
        }
      })
    },
    jump() {
      if (this.phone == "") {
        this.$message.error("请输入手机号");
        return;
      }
      const reg = /^1[34578]\d{9}$/;
      if (!reg.test(this.phone)) {
        this.$message.error("请输入正确的手机号");
        return false
      }
      if (this.phonenum == "") {
        this.$message.error("请输入验证码");
        return;
      }
      if (this.password == "") {
        this.$message.error("请输入密码");
        return;
      }
      var num = this.password.length
      if (num < 6 || num > 20) {
        this.$message.error("密码长度6-20位");
        return;
      }
      var that = this;
      ajax.post("/TokenAuth/ResetPwd", {
        Phone: that.phone,
        PhoneNum: that.phonenum,
        Password: that.password
      }, function (res) {
        if (res.result.success) {
          that.$message.success(res.result.msg);
          setTimeout(function () {
            that.$router.push({
              path: "/register/login",
            });
          }, 2000)
        } else {
          that.$message.error(res.result.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.submit-btn {
  background: #24a2aa;
  color: white;
  width: 100%;
  height: 40px; /* no */
  margin-right: 10px; /* no */
}
.title {
  font-size: 24px; /* no */
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #24a2aa;
  text-align: center;
  letter-spacing: 10px; /* no */
  padding: 29px 0 0 0; /* no */
}
.banner {
  background-image: url(/img/zhucebanner.png);
  width: 100%;
  height: 720px; /* no */
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  .box {
    width: 49.22%;
    height: 78.14%;
    display: flex;

    .left {
      width: 55%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .right {
      width: 45%;
      height: 100%;
      background: white;
      border-radius: 0 16px 16px 0; /* no */
    }
  }
}
/deep/.el-radio__input.is-checked .el-radio__inner {
  border-color: #24a2aa;
  background: #24a2aa;
}
.title-font {
  font-size: 24px; /* no */
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #24a2aa;
  text-align: center;
  letter-spacing: 10px; /* no */
  padding-top: 20px; /* no */
}
</style>
